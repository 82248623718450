import {
  ActionIcon,
  Group,
  Drawer as MDrawer,
  Menu,
  Space,
  Stack,
} from '@mantine/core'
import {
  CLOSE,
  MENU,
  EXPAND,
  LINK,
  ATTACH,
} from '../../../public/DrawerIcons/DrawerIcons'
import { ReactNode, useState } from 'react'
import DrawerTitle from './DrawerTitle'
import DrawerSubTitle from './DrawerSubTitle'
import DrawerFooter from './DrawerFooter'

type menuOption = {
  label: string
  onClick?: () => void
}
interface DrawerProps {
  opened: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children?: ReactNode
  footer?: ReactNode
  menuOptions?: menuOption[]
}

function Drawer({
  opened,
  setOpen,
  children,
  menuOptions,
  footer,
}: DrawerProps) {
  const [expand, setExpand] = useState(false)

  function closeAndExpand() {
    setOpen(false)
    setExpand(!expand)
    setOpen(true)
  }

  return (
    <MDrawer
      opened={opened}
      onClose={() => setOpen(false)}
      withCloseButton={false}
      position="right"
      size={expand ? '100%' : '50%'}
      transitionProps={{ duration: 200, timingFunction: 'linear' }}
    >
      <Group position="apart" mx="sm" mt="sm">
        <Group>
          <ActionIcon onClick={() => setOpen(false)}>
            <CLOSE />
          </ActionIcon>
          <ActionIcon onClick={() => closeAndExpand()}>
            <EXPAND />
          </ActionIcon>
        </Group>
        <Group>
          <ATTACH />
          <LINK />
          <Menu withArrow position="bottom-end">
            <Menu.Target>
              <ActionIcon>
                <MENU />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {menuOptions?.map((item: menuOption, index: number) => {
                return (
                  <Menu.Item onClick={item.onClick} key={'drawerkey-' + index}>
                    {item.label}
                  </Menu.Item>
                )
              })}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <Stack justify="space-between" h="100%">
        <Stack mx="5%" my="lg" spacing={0} h="100%">
          {children}
          <Space h="25vh" />
          {footer}
        </Stack>
      </Stack>
    </MDrawer>
  )
}

Drawer.Title = DrawerTitle
Drawer.SubTitle = DrawerSubTitle
Drawer.Footer = DrawerFooter

export default Drawer
