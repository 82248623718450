import { Group, Accordion, Text, Button } from '@mantine/core'
import { PaymentTransactionType } from 'app/models/dropdown-options'
import { PaymentParams } from 'app/models/payments-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { getSenders } from 'app/state/ducks/payments/selectors'
import { changeReceivedLenders } from 'app/state/ducks/payments/thunks'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  payment?: PaymentParams
}

export function PaymentDetailsAccordion({ payment }: Props) {
  const dispatch = useDispatch()
  const businessDate = useSelector(getBusinessDate)
  const senders = useSelector(getSenders)
  const [received, setReceived] = useState<boolean>(false)

  useEffect(()=>{
    if(payment?.payment.transactionType === 'NewBorrowingTransaction'){
      return
    }
    if(senders.length==0){
      return
    }
    setReceived(senders[0].paymentReceived)
  },[senders, payment]) 

  const changeReceived = async () => {
    if(!payment || senders.length==0){
      return
    }
    const response:any = await dispatch(changeReceivedLenders(payment, [{lender:senders[0].lenderId, paymentReceived:!received}]))
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Received status Failed',
        message: response.payload ?? 'Failed to change payment received status',
      })
      return
    }
  
    SuccessNotification({
      title: 'Successful changed payment received status',
      message: 'Status is updated.',
    })
    setReceived(!received)
  }

  return (
    <Accordion.Item value="PaymentDetails">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Payment Details
      </Accordion.Control>
      <Accordion.Panel>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Facility name
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {payment?.payment.facilityName ?? ''}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Transaction type
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {payment?.payment.transactionType
              ? PaymentTransactionType.find(
                p => p.value == payment.payment.transactionType
              )?.label ?? ''
              : ''}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Amount
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {formatNumberToCurrency(Number(payment?.payment.amount), 'USD')}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Currency
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {payment?.payment.currency ?? ''}
          </Text>
        </Group>
        {payment?.payment.transactionType!=='NewBorrowingTransaction' && 
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Payment Received?
          </Text>
          <Button
                      variant="outline"
                      onClick={() => changeReceived()}
                    >
                      {received ? (
                        <Text color="green" fz="1em">
                          Yes
                        </Text>
                      ) : (
                        <Text color="red">No</Text>
                      )}
                    </Button>
          </Group>
}
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Effective Date
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {(payment?.payment.effectiveDate ? stringToDate(payment.payment.effectiveDate) : businessDate).toLocaleDateString(
              'en-US'
            )}
          </Text>
        </Group>

        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            User
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {payment?.payment.opsAdminName}  
          </Text>
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
