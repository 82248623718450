interface HeaderSearchProps {
  links: {
    link: string
    label: string
    links?: { link: string; label: string }[]
  }[]
}

export const links: HeaderSearchProps = {
  links: [
    {
      label: 'My Work',
      link: '/mywork',
    },
  ],
}
