export const simDeal: any = {
    dealName: "LendOS Borrower $1K Jan:30:24",
    amount: "99000",
    borrowers: [
        {
            admin: "",
            id: "efc3a8b4-bf9e-11ee-801d-c75d89a94f3f"
        }
    ],
    guarantors: [
        {
            admin: "",
            id: "f109b588-bf9e-11ee-8029-c75d89a94f3f"
        }
    ],
    adminEntity: {
        admin: "",
        id: "f0d442ea-bf9e-11ee-8027-c75d89a94f3f"
    },
    currency: "USD",
    maturity: "2025-02-21",
    startDate: "2024-01-31",
    precedentDate: "2024-01-31",
    cusipIsin: "1111",
    lxId: "11111111",
    bbgId: "BBG123456",
    holidayCalendar: [
        {
            admin: "",
            id: "f71c814e-bf9e-11ee-802b-c75d89a94f3f"
        }
    ],
    approve: false,
    closing: "2024-01-31",
    opsAdmin: {
        admin: "",
        id: "144c90c4-bf9f-11ee-8058-c75d89a94f3f"
    },
    fees: [
        {
            feeRate: 10,
            feeAmount: 100,
            feeType: "ArrangementFee",
            firstPaymentDate: "2024-01-31",
            frequency: "Monthly",
        }
    ],
    hasError: false,
    ErrorMessage: "",
    hasSuccessfulEntitySave: false,
    customer_token: ""
}
