import { showNotification } from '@mantine/notifications'
import React from 'react'

type Props = {
  color?: string
  title?: string
  message?: string
  duration?: number
}

export const DisplayNotification: React.FC<Props> = ({
  color,
  title,
  message,
  duration = 7000,
}) => {
  showNotification({
    color: color,
    title: title,
    message: message,
    autoClose: duration,
  })
  return null
}

export const SuccessNotification: React.FC<Props> = ({
  title,
  message,
  duration = 7000,
}) => {
  showNotification({
    color: 'green',
    title: title,
    message: message,
    autoClose: duration,
  })
  return null
}

export const ErrorNotification: React.FC<Props> = ({
  title,
  message,
  duration = 7000,
}) => {
  showNotification({
    color: 'red',
    title: title,
    message: message,
    autoClose: duration,
  })
  return null
}
