import { Button, ButtonProps, Text } from '@mantine/core'
import { ReactNode } from 'react'

type Props = ButtonProps & {
  text?: string
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  children?: ReactNode
}

function SecondaryButton({ text, onClick, children, ...props }: Props) {
  return (
    <Button
      bg="lightgrey"
      h="4em"
      style={{ borderRadius: '4px', border: '1px solid grey' }}
      onClick={onClick}
      styles={{
        root: {
          '&:hover': {
            background: 'grey',
          },
        },
      }}
      {...props}
    >
      <Text
        color="#262626"
        fz="1.3em"
        fw={600}
        style={{
          fontFamily: 'Plus Jakarta Sans',
          color: 'black',
        }}
      >
        {text ?? children}
      </Text>
    </Button>
  )
}

export default SecondaryButton
