export const simFacility: any = {
    dynamicNameObj: {
        borrower: "LendOS Borrower",
        type: "TL",
        amount: "5500.0",
        date: "2024-03-31T00:00:00.000Z",
        currency: "USD"
    },
    name: "LendOS Borrower TL $6K Mar:31:24",
    dealId: {
        id: "c0eebe0a-c072-11ee-80c0-34aec10bc94f",
        admin: ''
    },
    amount: 5500,
    amortizationScheduleId: {
        admin: "",
        id: "d6e39965-9638-4e47-8c0a-5b32933a3822"
    },
    accrualFee: [],
    agentMei: "1111111111",
    holidayCalendar: [
        {
            id: "dd02685e-bfbd-11ee-802b-01a5fae8a926",
            admin: ""
        }
    ],
    adminEntity: {
        id: "d69cc0fe-bfbd-11ee-8027-01a5fae8a926",
        admin: ""
    },
    opsAdmin: {
        id: "fb5b1404-bfbd-11ee-8058-01a5fae8a926",
        admin: ""
    },
    currencies: [
        "USD"
    ],
    baseCurrency: "USD",
    borrowers: [
        {
            id: "d590568a-bfbd-11ee-801d-01a5fae8a926",
            admin: ""
        }
    ],
    isSublimit: false,
    lenderEligibilityCriteria: [
        "Existing_Lender"
    ],
    facilityType: "TL",
    pikOption: "PIK_All",
    startDate: "2024-01-31",
    maturity: "2024-03-31",
    cusipIsin: "1111",
    lxId: "11111112",
    bbgId: "BBG987654",
    borrowerPaymentInstructions: [
        {
            id: "ea42ee12-bfbd-11ee-8033-01a5fae8a926",
            admin: ""
        }
    ],
    subLimits: [],
    iroValues: [
        {
            margin: 1.1,
            indexOption: "FED FUNDS",
            currency: "USD",
            rounding: "NoRounding",
            dayBasis: "Actual_360",
            contractPeriod: [
                {
                    contractPeriod: "OneWeek",
                    cas: 0.0
                }
            ],
            rateInstruction: ""
        }
    ],
    restrictLoanDate: false,
    amendmentLenders: [],
    amendmentFees: [],
    amendmentAmortization: null,
    hasError: false,
    hasSuccessfulEntitySave: false,
    ErrorMessage: "",
    customer_token: ""
}
