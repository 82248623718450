import { CurrentLoansSnapshotParams, FacilityHistorySnapshotParams, FacilitySnapshotParams, LoanHistorySnapshotParams } from "app/models/snapshot-params";
import { formatNumberToCurrency } from "app/utils/util-functions";

export const generateHTML = (facility: FacilitySnapshotParams, index:number) => {
    return `
      <div class="history">
        <div class="history-container">
          <div class="heading">
            <h3>Facility #${index} Name: </h3>
            <h2>${facility.facilityName}</h2>
          </div>
          <h3>Facility #${index} Details:</h3>
          <table class="details-table">
            <tbody>
              <tr><td><strong>Facility Type:</strong></td><td>${facility.facilityType}</td></tr>
              <tr><td><strong>Administrative Agent:</strong></td><td>${facility.adminAgent}</td></tr>
              <tr><td><strong>Borrowers:</strong></td><td>${facility.borrowers}</td></tr>
              <tr><td><strong>Amount:</strong></td><td>${formatNumberToCurrency(Number(facility.amount),facility.currency)}</td></tr>
              <tr><td><strong>Currency:</strong></td><td>${facility.currency}</td></tr>
            </tbody>
          </table>
          <table class="details-table">
            <tbody>
              <tr><td><strong>Start Date:</strong></td><td>${facility.startDate}</td></tr>
              <tr><td><strong>Maturity Date:</strong></td><td>${facility.maturityDate}</td></tr>
              <tr><td><strong>Facility CUSIP/ISIN:</strong></td><td>${facility.facilityCUSIP}</td></tr>
              <tr><td><strong>Facility LXID:</strong></td><td>${facility.facilityLXID}</td></tr>
              <tr><td><strong>Facility BBG ID:</strong></td><td>${facility.facilityBBGID}</td></tr>
            </tbody>
          </table>
        </div>
        ${generateFacilityHistoryHTML(facility.facilityHistory, index, facility.currency)}
        ${generateLoansHTML(facility.currentLoans)}
      </div>
    `;
  };

   const generateFacilityHistoryHTML = (history: FacilityHistorySnapshotParams[], index:number, currency: string) => {
    return `
      <section class="facility-history">
          <h3>Facility #${index} History:</h3>
          <table>
              <thead>
                  <tr><th>History Date</th><th>Type</th><th>Amount</th></tr>
              </thead>
              <tbody>
                  ${history.map(event => `
                      <tr>
                          <td>${event.historyDate}</td>
                          <td>${event.type}</td>
                          <td>${formatNumberToCurrency(Number(event.amount),currency)}</td>
                      </tr>
                  `).join('')}
              </tbody>
          </table>
      </section>
    `;
  };

  const generateLoansHTML = (loans: CurrentLoansSnapshotParams[]) => {
    return `
      <section class="loan-history">
          <h3>Current Loans:</h3>
          <table>
              <thead>
                  <tr>
                      <th>Status</th>
                      <th>Index Rate Option</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Currency</th>
                      <th>Amount</th>
                      <th>Base Rate</th>
                      <th>Margin</th>
                      <th>All-In-Rate</th>
                  </tr>
              </thead>
              <tbody>
                  ${loans.map(loan => `
                      <tr>
                          <td>${loan.status}</td>
                          <td>${loan.indexRateOption}</td>
                          <td>${loan.startDate}</td>
                          <td>${loan.endDate}</td>
                          <td>${loan.currency}</td>
                          <td>${formatNumberToCurrency(Number(loan.transactionAmount),loan.currency)}</td>
                          <td>${loan.baseRate}%</td>
                          <td>${loan.margin}%</td>
                          <td>${loan.allInRate}%</td>
                      </tr>
                  `).join('')}
              </tbody>
          </table>
      ${loans.map((loan, index) => generateLoanHistoryHTML(loan.loanHistory, index + 1)).join('')}
      </section>
    `;
  };

  const generateLoanHistoryHTML = (loanHistory: LoanHistorySnapshotParams[], index: number) => {
    return `
      <section class="loan-history">
          <h3>Loan #${index} History:</h3>
          <table>
              <thead>
                  <tr>
                      <th>Status</th>
                      <th>Index Rate Option</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Currency</th>
                      <th>Transaction Type</th>
                      <th>Transaction Amount</th>
                      <th>New Balance Amount</th>
                      <th>Base Rate</th>
                      <th>Margin</th>
                      <th>All-In-Rate</th>
                  </tr>
              </thead>
              <tbody>
                  ${loanHistory.map(event => `
                      <tr>
                          <td>${event.status}</td>
                          <td>${event.indexRateOption}</td>
                          <td>${event.startDate}</td>
                          <td>${event.endDate}</td>
                          <td>${event.currency}</td>
                          <td>${event.transactionType}</td>
                          <td>${formatNumberToCurrency(Number(event.transactionAmount), event.currency)}</td>
                          <td>${formatNumberToCurrency(Number(event.newBalanceAmount), event.currency)}</td>
                          <td>${event.baseRate}%</td>
                          <td>${event.margin}%</td>
                          <td>${event.allInRate}%</td>
                      </tr>
                  `).join('')}
              </tbody>
          </table>
      </section>
    `;
  };
