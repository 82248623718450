import IonIcon from '@reacticons/ionicons'
import { ReactNode } from 'react';

export interface Route {
  link: string
  label: string
  icon?: ReactNode | (() => ReactNode);
  alt?: string
  isExtended?: boolean
  isExtendedMain?: boolean
  key?: string;
}

export const Data: Route[] = [
  {
    link: '/entitymanagement',
    label: 'Entities',
    icon: () => <IonIcon name="folder-open-outline" size="large" />,
    alt: 'Account Management Icons',
    key: 'entityManagement',
  },
  {
    link: '/dealmanagement',
    label: 'Deals',
    icon: () => <IonIcon name="briefcase-outline" size="large" />,
    alt: 'deal-management-icon',
    key: 'dealManagement',
  },
  {
    link: '/positions',
    label: 'Positions',
    icon: () => <IonIcon name="cellular-outline" size="large" />,
    alt: 'positions-icon',
    key: 'positions',
  },
  {
    link: '/assetservicing',
    label: 'Servicing',
    icon: () => <IonIcon name="card-outline" size="large" />,
    alt: 'asset-servicing-icon',
    key: 'servicing',
  },
  {
    link: '/releasequeue',
    label: 'Release Queue',
    icon: () => <IonIcon name="file-tray-full-outline" size="large" />,
    alt: 'release-queue-icon',
    key: 'releaseQueue',
  },
  {
    link: '/tradeclosing',
    label: 'Trades',
    icon: () => <IonIcon name="repeat-outline" size="large" />,
    alt: 'trade-closing-icon',
    key: 'tradeClosing',
  },
  // {
  //   link: '/reports',
  //   label: 'Reports',
  //   icon: () => <IonIcon name="newspaper-outline" size="large" />,
  //   alt: 'reports-icon',
  //   key: 'reports',
  // },
  {
    link: 'divider',
    label: '',
    key: 'divider',
  },
  {
    link: '/indexrateoptions',
    label: 'Maintenance',
    icon: () => <IonIcon name="apps-outline" size="large" />,
    alt: 'table-maintenance-icon',
    isExtendedMain: false,
    key: 'tableMaintenance',
  },
  {
    link: '/indexrateoptions',
    label: 'Index Rate Options',
    icon: () => <IonIcon name="bar-chart-outline" size="large" />,
    alt: 'interest-rate-options-icon',
    isExtended: true,
    key: 'indexRateOptions',
  },
  {
    link: '/usermanagement',
    label: 'Users',
    icon: () => <IonIcon name="people-outline" size="large" />,
    alt: 'user-management-icon',
    isExtended: true,
    key: 'userManagement',
  },
  {
    link: '/jobmanagement',
    label: 'Jobs',
    icon: () => <IonIcon name="clipboard-outline" size="large" />,
    alt: 'job-management-icon',
    key: 'jobManagement',
  },
  {
    link: '/holidaycalendars',
    label: 'Holiday Calendars',
    icon: () => <IonIcon name="calendar-outline" size="large" />,
    alt: 'holiday-calendars-icon',
    key: 'holidayCalendars',
  },
  // {
  //   link: '/simulations',
  //   label: 'Simulate',
  //   icon: <IonIcon name="play-circle-outline" size="large" />,
  //   alt: 'simulation-icon',
  //   key: 'simulations',
  // },
]

export const nestedKeys = ['userManagement', 'indexRateOptions']