import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Divider, NavLink } from '@mantine/core';
import { Route } from './types';

interface NavigationItemProps {
    item: Route;
    open: boolean;
    activePath: string;
    onClick: () => void;
}

const styles = {
    root: {
        '&[data-active]': {
            backgroundColor: '#32439A',
            borderRadius: '10px',
            ':hover': {
                backgroundColor: '#12237A',
            },
        },
    },
    label: {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.2em',
    },
}

const NavigationItem: React.FC<NavigationItemProps> = ({ item, open, activePath, onClick }) => {
    if (item.isExtended) return null;
    if (item.key === 'divider') return <Divider key={item.key} size="sm" />;

    const isActive = activePath.includes(item.link);
    const iconComponent = typeof item.icon === 'function' ? item.icon() : item.icon;

    return (
        <Link to={item.link} onClick={onClick} style={{ width: "-webkit-fill-available", display: 'flex' }}>
            <NavLink
                icon={iconComponent}
                label={open ? item.label : undefined}
                variant="filled"
                styles={styles}
                active={item.key === 'tableMaintenance' ? false : isActive}
                py="lg"
                my="7px"
            />
        </Link>
    );
};
export default NavigationItem;
