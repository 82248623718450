import { HoverCard, Group, Text } from '@mantine/core';
import { EntityParams } from 'app/models/entity-params';
import IonIcon from '@reacticons/ionicons';

interface Props {
    entities: EntityParams[];
    lenderId: string;
}

export default function AlertHoverCard({ entities, lenderId }: Props) {
    const entity = entities.find(entity => entity.id === lenderId && entity?.kycStatus !== 'ValidatedStatus');

    if (!entity) {
        return null;
    }

    return (
        <HoverCard width={580} shadow="md">
            <HoverCard.Target>
                <IonIcon size="large" name="alert-circle-outline" style={{ color: "red" }} className="alertAnchor" />
            </HoverCard.Target>
            <HoverCard.Dropdown className="hoverCard">
                <div className="hoverCard">
                    <div
                        style={{
                            backgroundColor: '#32439A',
                            width: '100%',
                            padding: '10px',
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Alert
                    </div>
                    <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Text
                            fz="14px"
                            fw={800}
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                padding: '10px',
                            }}
                        >
                            KYC Status needs to be Validated for payment release
                        </Text>
                    </Group>
                </div>
            </HoverCard.Dropdown>
        </HoverCard>
    );
}