import { Title } from '@mantine/core'

interface Props {
  children?: string
}

function DrawerSubTitle({ children }: Props) {
  return (
    <Title
      order={2}
      fw={400}
      fz={14}
      style={{
        fontFamily: 'Plus Jakarta Sans',
        color: '#45526C',
      }}
    >
      {children}
    </Title>
  )
}

export default DrawerSubTitle
