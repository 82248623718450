import Drawer from 'app/views/components/Drawer/Drawer'
import { Accordion, Button, Checkbox, Group, Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import { PaymentDetailsAccordion } from './accordion-payment-details'
import { PaymentWireInstructionsDetailsAccordion } from './accordion-wire-instructions'
// import { LenderAllocationAccordion } from './accordion-lender-allocations'
import { PaymentParams } from 'app/models/payments-params'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import PaymentModal from './payment-modal'
import { NotesAccordion } from 'app/views/components/Accordion/notes-accordion'
import { ContractType } from 'app/models/common-types'
import { AttachmentsAccordion } from 'app/views/components/Accordion/attachments-accordion/attachments-accordion'
import { useDispatch, useSelector } from 'react-redux'
import { getReceivers, getSenders } from 'app/state/ducks/payments/selectors'
import { changeFrontingOnPayment, loadReceivers, loadSenders } from 'app/state/ducks/payments/thunks'
import { LenderAllocationAccordion } from './accordion-lender-allocations'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { StatusPill } from 'app/views/components/pill/status-pill'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  row?: PaymentParams
}

export default function ReleaseQueueDrawer({ open, setOpen, row }: Props) {
  const dispatch = useDispatch()
  const senders = useSelector(getSenders)
  const receivers = useSelector(getReceivers)
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isCanceled, setIsCanceled] = useState<boolean>(false)

  useEffect(() => {
    if (!row) {
      return
    }
    dispatch(loadNoteByContract(row.id, ContractType.Payments))
    dispatch(loadSenders(row.id))
    dispatch(loadReceivers(row.id))
  }, [row])

  const changeFronting = async (value: boolean) => {
    setLoading(true)
    const response: any = await dispatch(changeFrontingOnPayment(row, value))
    setLoading(false)
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Fronting status Failed',
        message: response.payload ?? 'Failed to change fronting',
      })
      return
    }

    SuccessNotification({
      title: 'Successful changed fronting status',
      message: 'Status is updated.',
    })
  }

  if (!row) {
    return <></>
  }
  return (
    <>
      <Drawer
        opened={open}
        setOpen={setOpen}
        footer={
          ['Queued', 'Released', 'Rejected'].includes(row.status) ? (
            <Group position="apart">
              <Button
                variant="outline"
                onClick={() => {
                  setIsCanceled(true)
                  setOpen(false)
                  setOpenModal(true)
                }}
              >
                Cancel
              </Button>

              <PrimaryButton
                disabled={
                  loading ||
                  (
                    row.status === 'Queued' &&
                    !row.submitted &&
                    !row.payment.fronting &&
                    senders.some((sender) => !sender.paymentReceived)
                  )}
                onClick={() => {
                  setIsCanceled(false)
                  setOpen(false)
                  setOpenModal(true)
                }}
              >
                {row.status === 'Rejected'
                  ? "Queue"
                  : row.status === 'Released'
                    ? "Settle"
                    : row.submitted ? "Release" : "Submit"}
              </PrimaryButton>
            </Group>) : null
        }
      >
        <Drawer.Title>{row.payment.facilityName ?? ''}</Drawer.Title>
        <Group position="apart" my="lg">
          <StatusPill status={row.status} payment={row} />
          <Checkbox
            disabled={loading || row.status !== 'Queued' || row.submitted}
            label="Fronting"
            checked={row.payment.fronting}
            onChange={event => changeFronting(event?.currentTarget.checked)}
            styles={{
              label: {
                fontSize: '2em',
              },
            }}
          />
        </Group>
        <Stack justify="space-between" h="100%">
          <Stack spacing="lg">
            <Accordion
              variant="contained"
              multiple
              defaultValue={[
                'LenderAllocation',
                'PaymentDetails',
                'WireInstructions',
                'pdfs',
                'notes',
              ]}
              styles={{
                control: {
                  fontFamily: 'Plus Jakarta Sans',
                  '&[data-active]': {
                    backgroundColor: '#A4CAFE',
                  },
                },
              }}
            >

              <PaymentDetailsAccordion payment={row} />
              <LenderAllocationAccordion payment={row} closeDrawer={() => setOpen(false)} />
              {row.payment.transactionType === 'NewBorrowingTransaction' && receivers?.length > 0 && <PaymentWireInstructionsDetailsAccordion

              />
              }
              <NotesAccordion
                contractId={row?.id ?? ''}
                contractAdmin={row?.payment.agencyAdmin ?? ''}
                type={ContractType.Payments}
              />
              <AttachmentsAccordion
                id={row.id ?? ''}
                type={ContractType.Payments}
              />
            </Accordion>
          </Stack>
        </Stack>
      </Drawer>

      <PaymentModal
        open={openModal}
        setOpen={setOpenModal}
        payment={row}
        isCanceled={isCanceled}
      />
    </>
  )
}
