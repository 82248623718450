
import VerticalStepper from 'app/views/components/stepper-component'
import { useEffect, useRef, useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
    ChildLoanRef,
} from 'app/views/pages/deals-management/loans/loan-form-presentation'
import { useDispatch, useSelector } from 'react-redux'
import { FacilityParams } from 'app/models/facility-params'
import { LoanParams } from 'app/models/loan-params'
import { PrincipalPaymentParams } from 'app/models/principal-payments-params'
import { getLoanLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import LenderSummary, { ChildLenderSummaryRef } from './lender-summary/lender-summary'
import { loadLendersPositionsByLoan } from 'app/state/ducks/lenders-positions/thunks'
import UpdatePrincipalPaymentStep from './update-principal-payment-step'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import { ContractType } from 'app/models/common-types'
import { setNull } from 'app/state/ducks/notes/actions'

type Props = {
    facility?: FacilityParams
    payment: PrincipalPaymentParams | undefined
    loan: LoanParams
    onClose: () => void
    step?: number
}

interface CustomStepContentProps {
    onNext: () => void
    onPrevious: () => void
}

export const PrincipalPaymentScreen: React.FC<Props> = ({
    facility,
    loan,
    payment,
    onClose,
    step,
}) => {
    const [updatedPayment, setUpdatedPayment] = useState(payment)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!updatedPayment?.id) {
            dispatch(setNull(({ contractType: ContractType.Principals })))
            return
        }
        dispatch(loadNoteByContract(updatedPayment.id, ContractType.Principals))
    }, [updatedPayment])

    useEffect(() => {
        if (!loan) {
            return
        }
        dispatch(loadLendersPositionsByLoan(loan.id ?? ''))
    }, [loan])


    useEffect(() => {
        if (!payment) {
            return
        }

        setUpdatedPayment(payment)
    }, [payment])

    const PaymentContent: React.FC<CustomStepContentProps> = ({
        onNext,
    }) => {

        const childRef = useRef<ChildLoanRef>(null)
        const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
        const [loading, setLoading] = useState(false);

        const handleSavePaymentAndExit = async () => {
            if (childRef.current) {
                setLoading(true)
                await childRef.current.handleClick()
                setLoading(false)
                onClose()
            }
        }
        const handleSavePaymentAndNext = async () => {
            if (childRef.current) {
                setLoading(true)
                await childRef.current.handleClick()
                setLoading(false)
                onNext();
            }
        };

        return (

            <div className="create-new">
                <UpdatePrincipalPaymentStep
                    facility={facility}
                    loan={loan}
                    payment={updatedPayment}
                    setPayment={setUpdatedPayment}
                    ref={childRef}
                    setSaveButtonDisabled={setIsSaveButtonDisabled}
                />
                <div
                    className="btn-group"
                    style={{
                        marginTop: 50,
                        textAlign: 'right',
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <PrimaryButton
                        onClick={() => handleSavePaymentAndExit()}
                        className="form-button"
                        loading={loading}
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSavePaymentAndNext()}
                        className="form-button"
                        loading={loading}
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        loading={loading}
                        w="33%"
                    >
                        Exit
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const LenderAllocationContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildLenderSummaryRef>(null)
        const { data: lendersPositions } = useSelector(getLoanLendersPositionsWithLoading)
        const [loading, setLoading] = useState(false);

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
            }
        }

        const handleSavePaymentAndExit = async () => {
            if (childRef.current) {
                setLoading(true)
                await childRef.current.handleClick()
                setLoading(false)
                onClose()
            }
        }

        const handleSavePaymentAndSubmit = async () => {
            if (childRef.current) {
                setLoading(true)
                await childRef.current.handleClick()
                setLoading(false)
                onClose()
            }
        }

        return (
            <div className="create-new">
                <LenderSummary
                    payment={updatedPayment}
                    loan={loan}
                    ref={childRef}
                    lenderPositions={lendersPositions}
                    setPayment={setUpdatedPayment}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >

                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        loading={loading}
                        w="25%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSavePaymentAndExit()}
                        className="form-button"
                        loading={loading}
                        w="33%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSavePaymentAndSubmit()}
                        className="form-button"
                        loading={loading}
                        w="33%"
                    >
                        Save and Submit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        loading={loading}
                        w="25%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }


    const steps = [
        {
            label: '1. Payment',
            content: (
                <PaymentContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'i. Lender Summary',
            content: (
                <LenderAllocationContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },]

    return (
        <>
            {!updatedPayment || Object.keys(updatedPayment).length === 0 ? (
                //create
                <VerticalStepper
                    steps={steps}
                    disabledSteps={[1]}
                    step={step}
                />
            ) : (
                // edit
                <VerticalStepper
                    steps={steps}
                    disabledSteps={[]}
                    step={step}
                />
            )}
        </>
    )
}

