import Drawer from 'app/views/components/Drawer/Drawer'
import { Group, SegmentedControl, Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { LendersPosition } from 'app/models/lenders-position-params'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { ContactParams } from 'app/models/contact-params'
import ContactsAccordion from './contacts-accordion'
import { WireInstructionsAccordion } from './wire-instructions-accordion'
import { EntityParams } from 'app/models/entity-params'
import HistoryAccordion from './history-accordion'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  lendersPosition?: LendersPosition
}

enum Tab {
  history = 'History',
  contacts = 'Contacts',
  wireInstructions = 'Wire Instructions',
}

export default function EditLendersPositionDrawer({
  open,
  setOpen,
  lendersPosition,
}: Props) {
  const entities = useSelector(getEntities)
  const [tab, setTab] = useState<string>(Tab.contacts)
  const [entity, setEntity] = useState<EntityParams | undefined>(undefined)
  const wireInstructions = useSelector(getWireInstructions)
  const contacts = useSelector(getContacts)
  const [entityContacts, setEntityContacts] = useState<ContactParams[]>([])
  const [entityWireInstructions, setEntityWireInstructions] = useState<
    WireInstructionParams[]
  >([])

  useEffect(() => {
    if (!lendersPosition || !entities) {
      return
    }
    setEntity(entities.find(entity => entity.id == lendersPosition.lender.id))
  }, [lendersPosition, entities])


  useEffect(() => {
    if (!lendersPosition) {
      return
    }
    setTab(Tab.history)
  }, [lendersPosition])

  useEffect(() => {
    if (!entity) {
      return
    }
    if (wireInstructions) {
      setEntityWireInstructions(
        wireInstructions.filter(
          instruction => cleanUUID(instruction.entity.id) == entity.id
        )
      )
    }
  }, [entity, wireInstructions])

  useEffect(() => {
    if (!entity) {
      return
    }
    if (contacts) {
      setEntityContacts(
        contacts.filter(contact => cleanUUID(contact.entity.id) == entity.id)
      )
    }
  }, [entity, contacts])

  return (
    <>
      <Drawer opened={open} setOpen={setOpen}>
        <Drawer.Title>{entity?.entityName}</Drawer.Title>
        <Group position="apart" my="lg">
          <SegmentedControl
            value={tab}
            onChange={setTab}
            size="lg"
            data={[
              { value: Tab.history, label: 'History' },
              { value: Tab.contacts, label: 'Contacts' },
              { value: Tab.wireInstructions, label: 'Wire Instructions' },
            ]}
            styles={{
              controlActive: {
                backgroundColor: '#A4CAFE',
              },
            }}
          />
        </Group>
        <Stack justify="space-between" h="100%">
          <Stack spacing="lg">
            {tab === Tab.history && (
              <HistoryAccordion
                contacts={entityContacts}
                lendersPosition={lendersPosition}
                changeTabToWireInstructions={() => setTab(Tab.wireInstructions)}
              />
            )}
            {tab === Tab.contacts && (
              <ContactsAccordion
                contacts={entityContacts}
                lendersPosition={lendersPosition}
                changeTabToWireInstructions={() => setTab(Tab.wireInstructions)}
              />
            )}
            {tab === Tab.wireInstructions && (
              <WireInstructionsAccordion
                wireInstructions={entityWireInstructions}
                lendersPosition={lendersPosition}
                closeDrawer={() => setOpen(false)}
              />
            )}
          </Stack>
        </Stack>
      </Drawer>
    </>
  )
}
