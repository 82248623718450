import { Accordion } from '@mantine/core'
import { PaymentParams } from 'app/models/payments-params'
import React from 'react'
import { ReceiversTable } from './receivers-table'
import { SenderTable } from './senders-table'
interface Props {
  payment: PaymentParams
  closeDrawer: () => void
}

export function LenderAllocationAccordion({
  payment,
  closeDrawer
}: Props) {


  return (
    <Accordion.Item value="LenderAllocation">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Lender Allocations
      </Accordion.Control>
      <Accordion.Panel>
        {payment.payment.transactionType === 'NewBorrowingTransaction' ? <SenderTable payment={payment} /> : <ReceiversTable payment={payment} closeDrawer={closeDrawer}/>}
      </Accordion.Panel>
    </Accordion.Item>
  )
}
