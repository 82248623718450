import {
    Group,
    Menu,
    Center,
    Container,
    createStyles,
} from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react'
import Logo from '../../navigation/Logo'
import CollapsedLogo from '../../navigation/CollapsedLogo'
import HeaderStyles from 'app/views/components/layout/Header/HeaderStyles'
import { links } from './HeaderSearch'
import { CreateNewButton } from './CreateNewButton'
import { useNavigate } from 'react-router-dom'
import IonIcon from '@reacticons/ionicons'

type LayoutHeaderProps = {
    open: boolean;
};

export const LogoHeader: React.FC<LayoutHeaderProps> = ({ open }) => {
    const navigate = useNavigate()

    const { classes } = createStyles(theme => HeaderStyles(theme))()
    const { isAuthenticated, } = useAuth0()


    const handleHeaderClick = (link: string) => {
        navigate(link)
    }

    const items = links.links.map(link => {
        const menuItems = link.links?.map(item => (
            <Menu.Item fz="1.3em" key={item.link}>
                {item.label}
            </Menu.Item>
        ))

        return (
            <Menu key={link.label} trigger="click">
                <Menu.Target>
                    <a
                        href={link.link}
                        className={classes.link}
                        onClick={event => {
                            event.preventDefault()
                            handleHeaderClick(link.link)
                        }}
                        style={{
                            whiteSpace: 'nowrap',
                            fontSize: '1.3em',
                            borderRadius: '10px',
                            padding: '6px 30px',
                            margin: '0px 15px 0px 0px',
                            height: '100%',
                            backgroundColor: window.location.href.includes('/mywork')
                                ? '#A4CAFE'
                                : undefined,
                            boxShadow: window.location.href.includes('/mywork')
                                ? '0 2px 4px rgb(0 0 0 / 12%)'
                                : undefined,
                        }}
                    >
                        <Center>
                            <span
                                className={classes.linkLabel}
                            >
                                {link.label}
                            </span>
                            {menuItems ? <IonIcon name='chevron-down-outline' /> : null}
                        </Center>
                    </a>
                </Menu.Target>
                <Menu.Dropdown>{menuItems}</Menu.Dropdown>
            </Menu>
        )
    })

    return (
        <Group
            sx={{ height: '100%' }}
            spacing='10px'
            className={classes.hiddenMobile}
            noWrap={true}
        >
            <Container mr={open ? "0px" : "6px"} ml={open ? "40px" : '0px'} mb={0} style={{ pointerEvents: 'none' }}>
                {open ? <Logo /> : <CollapsedLogo />}
            </Container>
            {isAuthenticated && items}
            {isAuthenticated && <CreateNewButton />}
        </Group>

    )
}
