import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { PageLoader } from 'app/views/components/layout/page-loader'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { RightPedalRoutes } from './routes'

export const MainSwitch: React.FC = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    )
  }
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Plus Jakarta Sans',
        colors: {
          'primary-blue': [
            '#ECEEF9',
            '#C9CFEE',
            '#A6B0E2',
            '#8491D7',
            '#6173CC',
            '#3F54C1',
            '#32439A',
            '#263273',
            '#19214D',
          ],
        },
      }}
    >
      <ModalsProvider>
        <Notifications />
        <RightPedalRoutes />
      </ModalsProvider>
    </MantineProvider>
  )
}

export default MainSwitch
