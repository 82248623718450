import { HoverCard, Group, Text } from '@mantine/core'
import { WireInstructionParams } from 'app/models/wire-instruction-params'

interface Props {
    wireInstruction: WireInstructionParams
}


export default function WireInstructionHoverCard({ wireInstruction }: Props) {
  return (
    <HoverCard width={580} shadow="md">
        <HoverCard.Target>
          <Text>{wireInstruction.currency}</Text>
        </HoverCard.Target>
        <HoverCard.Dropdown>
        <div
        style={{
          backgroundColor: '#32439A',
          width: '100%',
          padding: '10px',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Wire Instruction
      </div>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Currency
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.currency}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Bank ABA #
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.accountWithInstitutionBankAba}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Account with Institution Name
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.accountWithInstitutionName}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Beneficiary Customer Name
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.beneficiaryCustomerName}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Beneficiary Account #
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.beneficiaryAccountNumber}

          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Payment Reference
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {wireInstruction.paymentReference}
          </Text>
        </Group>
        </HoverCard.Dropdown>
      </HoverCard>
  )
}
