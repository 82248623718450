import { Button, Group, Menu, Modal, Table, Title, Stack } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { PaymentParams, ReceiverParams } from 'app/models/payments-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getReceivers } from 'app/state/ducks/payments/selectors'
import { rejectPayment } from 'app/state/ducks/payments/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import WireInstructionHoverCard from 'app/views/components/hover-cards/wire-instruction-hover-card'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertHoverCard from 'app/views/components/hover-cards/alert-hover-card'

interface Props {
  payment: PaymentParams
  closeDrawer: () => void
}


export const ReceiversTable = ({ payment, closeDrawer }: Props) => {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const receivers = useSelector(getReceivers)
  const [open, setOpen] = React.useState(false)
  const [lender, setLender] = React.useState<ReceiverParams | undefined>()

  function rejectReceiver(l: ReceiverParams): void {
    setOpen(true)
    setLender(l)
  }

  async function handleProceedClick() {
    if (!lender) return
    const result: any = await dispatch(rejectPayment(lender))
    if (!result.success) {
      ErrorNotification({
        title: 'Reject Payment Failed',
        message: result.payload ?? 'Failed to reject payment',
      })
      return
    }

    SuccessNotification({
      title: 'Successful Rejected Payment',
      message: 'Receiver has been removed from this payment',
    })
    closeDrawer()
  }

  return (
    <>
      <Table fontSize="1.2em">
        <thead>
          <tr>
            <th>Lender</th>
            <th>Wire instruction</th>
            <th>Amount</th>
            {['Released', 'Queued'].includes(payment.status) ? <th>Actions</th> : null}
            <th>Alert</th>
          </tr>
        </thead>
        <tbody>
          {receivers.map((lender: ReceiverParams, index: number) => {
            return (
              <tr key={'lenderallocation-' + index}>
                <td>
                  {entities &&
                    entities.length > 0 &&
                    (entities.find(entity => entity.id === lender.lenderId.id)
                      ?.entityName ?? '')}
                </td>
                <td><WireInstructionHoverCard wireInstruction={lender.wireInstruction} /></td>
                <td>
                  {formatNumberToCurrency(Number(lender.amount), lender.wireInstruction.currency)}
                </td>
                {['Released', 'Queued'].includes(payment.status) ? (
                  <td>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <div style={{ width: 'fit-content' }}>
                          <PrimaryDropDown>Actions</PrimaryDropDown>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>Receiver</Menu.Label>
                        <Menu.Item
                          onClick={() => rejectReceiver(lender)}
                          icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                        >
                          Reject
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </td>
                )
                  : null
                }
                <td style={{ position: "relative" }}>
                  <AlertHoverCard entities={entities} lenderId={lender.lenderId.id} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      {open && lender ? (<Modal opened={open} onClose={() => setOpen(false)} size="lg">
        <Stack>
          <Title>Reject {formatNumberToCurrency(Number(lender?.amount ?? 0), payment.payment.currency ?? 'USD')} Payment </Title>
          <Group position="right">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              style={{ backgroundColor: '#32439A' }}
              onClick={() => handleProceedClick()}
            >
              Proceed
            </Button>
          </Group>
        </Stack>
      </Modal>) : null}
    </>
  )
}
