import { Group, Accordion, Text } from '@mantine/core'
import { getReceivers } from 'app/state/ducks/payments/selectors'
import { useSelector } from 'react-redux'


export function PaymentWireInstructionsDetailsAccordion() {
  const receivers = useSelector(getReceivers)
  return (
    <Accordion.Item value="WireInstructions">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Wire Instructions
      </Accordion.Control>
      <Accordion.Panel>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Currency
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {receivers[0].wireInstruction.currency}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Bank ABA #
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {receivers[0].wireInstruction.accountWithInstitutionBankAba}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Account with Institution Name
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {receivers[0].wireInstruction.accountWithInstitutionName}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Beneficiary Customer Name
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {receivers[0].wireInstruction.beneficiaryCustomerName}
          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Beneficiary Account #
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {receivers[0].wireInstruction.beneficiaryAccountNumber}

          </Text>
        </Group>
        <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Payment Reference
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            New Borrowing
          </Text>
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
