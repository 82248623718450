import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, Text } from '@mantine/core'
import { NoticeParams } from 'app/models/notices-params'

export default function NoticePreview() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const rowData = searchParams.get('data')
    const selectedNotice: NoticeParams = JSON.parse(decodeURIComponent(rowData || ''))

    const [previewMode, setPreviewMode] = useState(false)

    useEffect(() => {
        const isPreview = selectedNotice !== null
        setPreviewMode(isPreview)
    }, [selectedNotice])

    if (!previewMode) {
        return null
    }

    const renderMetaData = () => {
        switch (selectedNotice.metaData.tag) {
            case 'NewBorrowingMetaData':
                return Object.keys(selectedNotice.metaData.value.newBorrowingGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.newBorrowingGlobal[key]}
                    </Text>
                ))
                break
            case 'RolloverMetaData':
                return Object.keys(selectedNotice.metaData.value.rolloverGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.rolloverGlobal[key]}
                    </Text>
                ))
            case 'PrincipalPaymentMetaData':
                return Object.keys(selectedNotice.metaData.value.principalPaymentGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.principalPaymentGlobal[key]}
                    </Text>
                ))
                break
            case 'NewBorrowingInvoiceMetaData':
                return Object.keys(selectedNotice.metaData.value.newBorrowingInvoiceGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.newBorrowingInvoiceGlobal[key]}
                    </Text>
                ))
                break
            case 'RolloverInvoiceMetaData':
                return Object.keys(selectedNotice.metaData.value.rolloverInvoiceGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.rolloverInvoiceGlobal[key]}
                    </Text>
                ))
                break
            case 'PrincipalPaymentInvoiceMetaData':
                return Object.keys(selectedNotice.metaData.value.principalPaymentInvoiceGlobal).map((key) => (
                    <Text key={key}>
                        {key}: {selectedNotice.metaData.value.principalPaymentInvoiceGlobal[key]}
                    </Text>
                ))
                break
            default:
                throw new Error('Invalid notice type')
        }
    }

    return (
        <PageLayout>
            <Paper style={{ padding: '20px' }}>
                <Text style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                    Facility Information
                </Text>
                <Text>Facility Name: {selectedNotice.facilityName}</Text>
                <Text>Status: {selectedNotice.status}</Text>
                <Text>Facility CUSIP/ISIN: {selectedNotice.facilityCusipIsin}</Text>
                <Text>Notice Type: {selectedNotice.noticeType}</Text>
                <Text>Effective Date: {selectedNotice.effectiveDate}</Text>
                <Text>Receiver: {selectedNotice.receiver}</Text>
                <Text>No of Notices: {selectedNotice.numberOfNotices}</Text>

                {selectedNotice.metaData && (
                    <>
                        <Text style={{ marginTop: '20px', marginBottom: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                            Metadata
                        </Text>
                        {renderMetaData()}
                    </>
                )}
            </Paper>
        </PageLayout>
    )
}
