import React, { useEffect, useState } from 'react'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { Group, Stack } from '@mantine/core'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { positionsKPIData } from './positions-kpi-data'
import { useDispatch, useSelector } from 'react-redux'
import { loadLendersPositions } from 'app/state/ducks/lenders-positions/thunks'
import { getLenderPositionsLoader, getLendersPositions } from 'app/state/ducks/lenders-positions/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { LendersPosition, LendersPositionWithMapping } from 'app/models/lenders-position-params'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { createLendersPositionsWithMapping } from './positions-util'
import MainTable from 'app/views/components/Table/MainTable'
import { colsFacilitiesPositions } from './positions-facilities-columnDefs'
import { colsDealsPositions } from './positions-deals-columnDefs'
import { colsEntitiesPositions } from './positions-entities-columnDefs'
import { MRT_ColumnDef } from 'mantine-react-table'

interface props {
  tab: string
}

export function PositionsDrillPage({ tab }: props) {
  const dispatch = useDispatch()

  const allPositions: LendersPosition[] = useSelector(getLendersPositions)
  const positions = allPositions.filter((position: LendersPosition) => position?.contractType === 'Facilities')
  const deals = useSelector(getDeals)
  const entities = useSelector(getEntities)
  const facilities = useSelector(getFacilities)
  const contacts = useSelector(getContacts)
  const lenderPositionsLoader = useSelector(getLenderPositionsLoader)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(lenderPositionsLoader)
  }, [lenderPositionsLoader])

  useEffect(() => {
    dispatch(loadLendersPositions())
    dispatch(loadEntities())
    dispatch(loadAllFacilities())
    dispatch(loadAllDeals())
    dispatch(loadContacts())
  }, [])

  const positionsWithMapping: LendersPositionWithMapping[] = createLendersPositionsWithMapping(positions, deals, entities, facilities, contacts)
  const kpiData = positionsKPIData(positionsWithMapping)

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; columns: MRT_ColumnDef<LendersPositionWithMapping>[]; sortedPositions: LendersPositionWithMapping[] } } = {
    positionsDeal: {
      route: '/positions/deal', label: 'DEALS', active: tab === 'positionsDeal' ? true : false, columns: colsDealsPositions, sortedPositions: [...positionsWithMapping].sort((a, b) =>
        (a.dealName).localeCompare(b.dealName))
    },
    positionsFacility: { route: '/positions/facility', label: 'FACILITIES', active: tab === 'positionsFacility' ? true : false, columns: colsFacilitiesPositions, sortedPositions: [...positionsWithMapping].sort((a, b) => (a.facilityName).localeCompare(b.facilityName)) },
    positionsEntity: { route: '/positions/entity', label: 'ENTITIES', active: tab === 'positionsEntity' ? true : false, columns: colsEntitiesPositions, sortedPositions: [...positionsWithMapping].sort((a, b) => (a.entityName).localeCompare(b.entityName)) },
  }

  return (
    <>
      <Stack w="100%">
        <Group w="100%" position="apart" className='tabsGapsActivity'>
          <Group position="right">
            <SegmentControlUpdated tabs={tabs} />
          </Group>
        </Group>

        <KPI_Section
          kpiData={kpiData}
          isLoading={loading}
        />
        <MainTable
          tableName='Positions Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="auto"
          minHeight="0px"
          columnDefs={tabs[tab].columns}
          data={tabs[tab].sortedPositions}
          isLoading={loading}
          setRow={(e: LendersPositionWithMapping, cellName: string) => {
            if (cellName != 'id') {
              console.log('row clicked', e)
            }
          }}
        />
      </Stack>
    </>
  )
}
