import { Title } from '@mantine/core'

interface Props {
  children?: string
}

function DrawerTitle({ children }: Props) {
  return (
    <Title
      order={1}
      fw={600}
      style={{
        fontSize: '3rem',
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      {children}
    </Title>
  )
}

export default DrawerTitle
